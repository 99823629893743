import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, computed, effect, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { gql } from '@apollo/client/core';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { GraphqlClientService } from '@rcg/graphql';
import { IntlModule, tr } from '@rcg/intl';
import { MessageService } from '@rcg/standalone';
import { combineLatest, firstValueFrom, from, map, retry } from 'rxjs';
import { AuthService } from '../../../auth.service';
import { authBaseUrl } from '../../../constants';
import { CenteredCardComponent } from '../../centered-card/centered-card.component';
import { ForgotPasswordComponent } from '../forgot/forgot.component';

const appQuery = gql`
  query SSOAppQuery($host: String!) {
    data: identity_sso_apps_by_pk(host: $host) {
      app_name
      sso_only
    }
  }
`;

interface SSOApp {
  name: string;
  ssoOnly: boolean;
}

@Component({
  selector: 'rcg-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormlyModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    IntlModule,
    CenteredCardComponent,
    ForgotPasswordComponent,
  ],
})
export class LoginComponent implements OnInit {
  private static _appCache?: Promise<SSOApp | null | undefined>;

  public readonly appS = toSignal<SSOApp | null | undefined>(
    from(
      LoginComponent._appCache ??
        (LoginComponent._appCache = firstValueFrom(
          this.gqlClient
            .anonymousQuery<{ data?: { app_name?: string; sso_only?: boolean } }>({
              query: appQuery,
              variables: {
                host: window.location.host,
              },
            })
            .pipe(
              map((response) =>
                response?.data?.app_name
                  ? {
                      name: response.data.app_name,
                      ssoOnly: response.data.sso_only ?? false,
                    }
                  : null,
              ),
              retry({ count: Infinity, delay: 500 }),
            ),
        )),
    ),
  );

  public readonly ssoOnlyS = computed(() => this.appS()?.ssoOnly ?? false);
  public readonly appLoadingS = computed(() => this.appS() === undefined);

  public readonly loggingInS = signal(false);

  private readonly forceFieldsDisableS = signal(false);
  private readonly fieldsDisabledS = computed(() => this.forceFieldsDisableS() || this.ssoOnlyS() || this.loggingInS());

  public readonly showForgotS = signal(false);

  public static readonly model = { email: '', password: '' };
  public readonly model = LoginComponent.model;

  constructor(
    public authService: AuthService,
    private messageService: MessageService,
    private gqlClient: GraphqlClientService,
    private route: ActivatedRoute,
    private router: Router,
    private changeRef: ChangeDetectorRef,
  ) {
    effect(() => {
      const fnName = this.fieldsDisabledS() ? 'disable' : 'enable';

      for (const field of Object.values(this.form.controls)) {
        field[fnName]();
      }

      this.changeRef.markForCheck();
    });
  }

  private readonly translationsS = toSignal(combineLatest([tr('email'), tr('enter_email'), tr('password'), tr('enter_password')]));

  form = new UntypedFormGroup({});

  public readonly fieldsS = computed<FormlyFieldConfig[]>(() => {
    if (this.ssoOnlyS()) return [];

    const translations = this.translationsS();

    if (!translations) return [];
    const [email, enterEmail, password, enterPassword] = translations;

    const disabled = this.fieldsDisabledS();

    return [
      {
        key: 'email',
        type: 'input',
        props: {
          type: 'email',
          label: email,
          placeholder: enterEmail,
          required: true,
          disabled,
          pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
        },
      },
      {
        key: 'password',
        type: 'input',
        props: {
          type: 'password',
          attributes: { autocomplete: 'off' },
          label: password,
          placeholder: enterPassword,
          required: true,
          disabled,
        },
      },
    ];
  });

  ngOnInit(): void {
    this.model.password = '';
  }

  async execLogin(loginFn: () => Promise<void> | void) {
    try {
      this.loggingInS.set(true);

      await loginFn();
    } catch (error) {
      this.messageService.showErrorSnackbar('Napaka pri prijavi.', error, 5);
      console.error('Login error', error);
    }

    this.loggingInS.set(false);
  }

  async onSubmit(): Promise<void> {
    await this.execLogin(async () => {
      if (!this.form.valid || !this.model.email || !this.model.password) {
        this.messageService.showErrorSnackbar('Napaka: E-mail ali geslo nista veljavna', '', 5);
        return;
      }

      await this.authService.login(this.model.email, this.model.password);
      this.model.password = '';
    });
  }

  async sso() {
    this.loggingInS.set(true);

    const stateParams = {
      path: window.location.pathname,
      search: window.location.search,
      hash: window.location.hash,
    };

    window.location.href = `${authBaseUrl}/sso/microsoft/login/${this.appS()!.name}?appStateParams=${encodeURIComponent(
      JSON.stringify(stateParams),
    )}`;
  }

  checkFormClick(event: MouseEvent) {
    if (this.loggingInS()) event.preventDefault();
  }
}
