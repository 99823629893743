import { Injectable } from '@angular/core';
import { EmlEmail } from '../../../models';
import { replaceHtmlRegexWithBlobUrls } from '../fn/blob';
import { ResolverCheckFn, ResolverResolveFn } from '../models/resolver.model';

@Injectable()
export abstract class HTMLAttachmentResolver<T extends unknown[]> {
  protected abstract check: ResolverCheckFn<T>;
  protected abstract resolve: ResolverResolveFn<T>;
  public abstract sealBlobs: (html: string) => string;
  public abstract bakeBlobs: (html: string) => string | Promise<string>;

  private findRegexMatches(html: string, regex: RegExp): string[] {
    const matches: string[] = [];

    html.replace(regex, (substr, ...args) => {
      if (substr && args[0]) matches.push(args[0]);
      return '';
    });

    return matches;
  }

  async resolveAttachments(
    html: string,
    unresolvableAttachments: string[],
    emlData?: [string, EmlEmail],
    updateProgress?: (progress: number, html?: string) => void,
  ) {
    const good = this.check(html, emlData);
    if (!good) return [html, []];

    const regex = good[0];
    let matches = [...new Set(this.findRegexMatches(html, regex))];
    matches = matches.filter((att) => !unresolvableAttachments.includes(att.toString()));

    if (!matches.length) return [html, []];

    let progressBlobUrls: Record<string, string | null> = {};
    let lastHtmlProgressTime = new Date().getTime();

    let progressUpdateFn: ((progress: number, addBlobUrls?: Record<string, string | null>) => void) | undefined;

    if (updateProgress) {
      progressUpdateFn = (progress: number, addBlobUrls?: Record<string, string | null>) => {
        progressBlobUrls = {
          ...progressBlobUrls,
          ...addBlobUrls,
        };

        let newHtml: string | undefined;

        if (addBlobUrls) {
          const now = new Date().getTime();

          if (now - lastHtmlProgressTime >= 500) {
            lastHtmlProgressTime = now;
            newHtml = replaceHtmlRegexWithBlobUrls(html, progressBlobUrls, regex);
          }
        }

        return [updateProgress(progress * 0.99, newHtml), []];
      };
    }

    const blobUrls = await (this.resolve as ResolverResolveFn<unknown[]>)(matches, progressUpdateFn ?? (() => {}), ...good.slice(1));

    const unresolved: string[] = [];

    Object.entries(blobUrls).forEach((e) => {
      if (e[1] === null) unresolved.push(e[0].toString());
    });

    return [replaceHtmlRegexWithBlobUrls(html, blobUrls, regex), unresolved];
  }
}
