import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Signal,
  ViewChild,
  ViewChildren,
  signal,
} from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MessageService, OtpPinInputComponent } from '@rcg/standalone';
import { AuthService } from '../../../auth.service';

@Component({
  selector: 'rcg-mfa-totp-authenticator[numDigits]',
  templateUrl: './totp-authenticator.component.html',
  styleUrls: ['./totp-authenticator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, OtpPinInputComponent, MatProgressSpinnerModule],
})
export class MFATotpAuthenticatorComponent {
  constructor(private msg: MessageService, private auth: AuthService) {}

  @Input() numDigits!: Signal<number>;
  @Output() done = new EventEmitter<boolean>();

  @ViewChildren('digitField', { read: ElementRef })
  readonly digitFields!: ElementRef<HTMLElement>[];

  @ViewChild('pinInput')
  readonly pinInput!: OtpPinInputComponent;

  readonly processing = signal(false);

  private async _codeEntered(code: string) {
    await this.auth.mfa({
      type: 'OTP/RFC6238',
      code,
    });
  }

  async codeEntered(pin: string) {
    try {
      this.processing.set(true);
      await this._codeEntered(pin);
      this.done.emit(true);
    } catch (error) {
      this.msg.showErrorSnackbar('Napaka pri preverjanju MFA kode', error);

      this.pinInput.clear();
    } finally {
      this.processing.set(false);
    }
  }
}
