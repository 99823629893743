import { Attachment, EmlAttachment } from '../../models';

export function isEmlAttachment(attachment: Attachment | EmlAttachment): attachment is EmlAttachment {
  return 'emlId' in attachment && !!attachment.emlId;
}

type AttachmentParameter = Attachment | EmlAttachment | null | undefined;

export function attachmentIdentifier<A extends AttachmentParameter>(
  attachment: A,
): A extends null | undefined ? undefined : A extends Attachment ? number : `${number}/${string}` {
  if (!attachment) return undefined as ReturnType<typeof attachmentIdentifier<A>>;

  if (isEmlAttachment(attachment)) return `${attachment.emlId}/${attachment.partId}` as ReturnType<typeof attachmentIdentifier<A>>;
  return attachment.id as ReturnType<typeof attachmentIdentifier<A>>;
}

export function attachmentFilename<A extends AttachmentParameter>(attachment: A) {
  const a = attachment as { file_name?: string; fileName?: string } | null | undefined;
  return a?.file_name ?? a?.fileName;
}

export function attachmentContentType<A extends AttachmentParameter>(attachment: A) {
  const a = attachment as { content_type?: string; contentType?: string } | null | undefined;
  return a?.content_type ?? a?.contentType;
}
