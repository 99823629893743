import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { ModulesService } from '../services/modules.service';

export function allowedAccessToModuleGuard(pathMatchDepth: number): CanActivateFn {
  return async (_, state) => {
    const modulesService = inject(ModulesService);
    const router = inject(Router);

    const url = (state.url.startsWith('/') ? state.url.slice(1) : state.url).split('/').slice(0, pathMatchDepth).join('/');

    const isAllowed = await firstValueFrom(modulesService.isModuleAllowed$(url));
    if (isAllowed) return true;

    return router.createUrlTree(['/']);
  };
}
