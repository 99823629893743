import { DataConnector, DataConnectorConfig, DataQuery, DataReferenceContext, dataConnectorRegistry } from "@npm-libs/ng-templater";
import { BehaviorSubject, Observable, Subscription, share, shareReplay } from "rxjs";

import * as dot from 'dot-object';

export class RcgSingleDataConnector {
    private _data$ = new BehaviorSubject<{ [x: string]: unknown }>({});
    private _runtimeData$ = new BehaviorSubject<{ [x: string]: unknown }>({});
    private _context$?: Observable<{ [x: string]: unknown }>;

    private dataConnectorConfig: DataConnectorConfig;
    private dataReferenceContext: DataReferenceContext;

    private dataConnectorsSub?: Subscription;
    private dataSubs?: Subscription;
    private runtimeDataSubs?: Subscription;
    private connectorKey!: string; // datapath#queryPath

    data$ = this._data$.asObservable().pipe(shareReplay({ refCount: true, bufferSize: 1 }));

    constructor(dataconnectorsConfig: DataConnectorConfig, connectorKey: string, debugData?: boolean) {
        this.dataConnectorConfig = dataconnectorsConfig;
        this.dataReferenceContext = {
            data$: this._data$,
            runtimeData$: this._runtimeData$,
            context$: this._context$,
        } as DataReferenceContext;
        this.connectorKey = connectorKey;
        this.subscribeDataConnector();

        // for debug only
        if (debugData === true) {
            this.dataSubs = this._data$.asObservable().subscribe((d) => console.log(`${this.connectorKey}_data`, d));
            this.runtimeDataSubs = this._runtimeData$.asObservable().subscribe((d) => console.log(`${this.connectorKey}_runtimeData`, d));
        }
    }

    updateQuery(connectorKey: string, dataQuery: DataQuery): void {
        this._runtimeData$.next({
            ...this._runtimeData$.value,
            [connectorKey]: dataQuery,
        });
    }

    private subscribeDataConnector() {
        if (!this.dataConnectorConfig) return;

        let ref: DataConnector<DataConnectorConfig> | undefined;

        for (const rdc of dataConnectorRegistry) {
            const r = rdc(this.dataConnectorConfig);
            if (!r) continue;
            if (r) {
                ref = r;
                break;
            }
        }

        if (!ref) return;

        const patch = (ref as Exclude<typeof ref, null | undefined>)?.connect(this.dataReferenceContext).pipe(/*debounceTime(100),*/share());

        const data$ = this._data$ as BehaviorSubject<{ [x: string]: unknown }>;

        this.dataConnectorsSub = patch.subscribe((patch) => {
            if (!patch) return;
            const data = data$.value;
            const [path, value] = patch;
            dot.set(path, value, data);
            data$.next(data);
        });
    }

    destroy(): void {
        this.dataSubs?.unsubscribe();
        this.runtimeDataSubs?.unsubscribe();
        this.dataConnectorsSub?.unsubscribe();

        this._data$?.complete();
        this._runtimeData$?.complete();
    }

}
