import { DataConnectorConfig, DataQuery, DataReferenceContext, dataConnectorRegistry } from '@npm-libs/ng-templater';
import { BehaviorSubject, Observable, Subscription, buffer, debounceTime, filter, map, merge, share, shareReplay } from 'rxjs';

import * as dot from 'dot-object';

export class RcgGroupedDataConnectors {
  _data$ = new BehaviorSubject<{ [x: string]: unknown }>({});
  _runtimeData$ = new BehaviorSubject<{ [x: string]: unknown }>({});
  _context$?: Observable<{ [x: string]: unknown }>;

  data$ = this._data$.asObservable().pipe(shareReplay({ refCount: true, bufferSize: 1 }));

  getDataSliceValue(key: string) {
    return this._data$.value[key];
  }

  getDataSlice$(key: string): Observable<unknown> {
    return this._data$.asObservable().pipe(
      // eslint-disable-next-line no-prototype-builtins
      filter((d) => !!d && d.hasOwnProperty(key) && d[key] !== undefined),
      // distinctUntilKeyChanged(key),
      map((d) => d[key]),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  private dataConnectorConfigs: DataConnectorConfig[] = [];
  private dataReferenceContext: DataReferenceContext;

  private dataConnectorsSub?: Subscription;
  private dataSubs?: Subscription;
  private runtimeDataSubs?: Subscription;

  constructor(
    dataconnectorsConfig: DataConnectorConfig[],
    debugData?: boolean,
    data$?: BehaviorSubject<{ [x: string]: unknown }>,
    runtimeData$?: BehaviorSubject<{ [x: string]: unknown }>,
    context$?: Observable<{ [x: string]: unknown }>,
  ) {
    this.dataConnectorConfigs = dataconnectorsConfig;

    if (data$) this._data$ = data$;
    if (runtimeData$) this._runtimeData$ = runtimeData$;
    if (context$) this._context$ = context$;

    this.dataReferenceContext = {
      data$: this._data$,
      runtimeData$: this._runtimeData$,
      context$: this._context$,
    } as DataReferenceContext;

    this.subscribeDataConnectors();

    // for debug only
    if (debugData === true) {
      this.dataSubs = this._data$.asObservable().subscribe((d) => console.log('data', d));
      this.runtimeDataSubs = this._runtimeData$.asObservable().subscribe((d) => console.log('runtimeData', d));
    }
  }

  updateQuery(connectorKey: string, dataQuery: DataQuery): void {
    this._runtimeData$.next({
      ...this._runtimeData$.value,
      [connectorKey]: dataQuery,
    });
  }

  // TODO: force destroy in caller -  add decorator methodMustBeCalledOnDestroy or use @Get.NgAutoDispose
  destroy(): void {
    this.dataSubs?.unsubscribe();
    this.runtimeDataSubs?.unsubscribe();
    this.dataConnectorsSub?.unsubscribe();
  }

  private subscribeDataConnectors() {
    if (!this.dataConnectorConfigs || !this.dataConnectorConfigs.length) return;

    const data$ = this._data$ as BehaviorSubject<{ [x: string]: unknown }>;

    const connectors = this.dataConnectorConfigs
      .map((connectorConfig) => {
        for (const rdc of dataConnectorRegistry) {
          const ref = rdc(connectorConfig);
          if (ref) return ref;
        }

        return null;
      })
      .filter((c) => !!c)
      .map((c) => {
        return (c as Exclude<typeof c, null | undefined>)?.connect(this.dataReferenceContext);
      });

    const merged$ = merge(...connectors).pipe(share());
    const buffered$ = merged$.pipe(buffer(merged$.pipe(debounceTime(100))));

    this.dataConnectorsSub = buffered$.subscribe((patches) => {
      if (!patches.length) return;

      const data = data$.value;
      for (const [path, value] of patches) {
        dot.set(path, value, data);
      }
      data$.next(data);
    });
  }
}
