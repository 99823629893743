import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DateToNowPipe } from './date-to-now.pipe';
import { ExternalNumberToEventIdPipe } from './external-number-to-event-id.pipe';
import { IsTodayPipe } from './is-today.pipe';
import { PlaintextForHtmlPipe } from './plaintext-for-html.pipe';
import { ReplaceLineBreaksPipe } from './replace-line-breaks.pipe';
import { SecondsToTime, SecondsToTimeShowSeconds } from './seconds-to-time.pipe';
import { TrustPipe } from './trust.pipe';

@NgModule({
  declarations: [
    DateToNowPipe,
    SecondsToTime,
    SecondsToTimeShowSeconds,
    ReplaceLineBreaksPipe,
    IsTodayPipe,
    TrustPipe,
    ExternalNumberToEventIdPipe,
    PlaintextForHtmlPipe,
  ],
  exports: [
    DateToNowPipe,
    SecondsToTime,
    SecondsToTimeShowSeconds,
    ReplaceLineBreaksPipe,
    IsTodayPipe,
    TrustPipe,
    ExternalNumberToEventIdPipe,
    PlaintextForHtmlPipe,
  ],
  imports: [CommonModule],
})
export class RcgPipesModule {}
