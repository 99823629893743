import { Pipe, PipeTransform } from '@angular/core';
import { DateUtils } from '@rcg/standalone';

@Pipe({
  name: 'secondsToTime',
})
export class SecondsToTime implements PipeTransform {
  transform(value: number | undefined): string {
    return DateUtils.secondsToTime(value);
  }
}

@Pipe({
  name: 'secondsToTimeShowSeconds',
})
export class SecondsToTimeShowSeconds implements PipeTransform {
  transform(value: number | undefined): string {
    return DateUtils.secondsToTime(value, true);
  }
}
