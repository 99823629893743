import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { UpdateService } from './services/update.service';

@NgModule({
  id: 'RCG-CORE-MODULE',
  imports: [CommonModule, HttpClientModule, MatSnackBarModule],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        UpdateService,
        {
          provide: APP_INITIALIZER,
          // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
          useFactory: (_: UpdateService) => () => {},
          deps: [UpdateService],
          multi: true,
        },
      ],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Prevent import of core module in any module other then AppModule
    if (parentModule) {
      console.error('CoreModule is already loaded.');
    }
  }
}
