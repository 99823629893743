export async function captureScreenshot() {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d')!;
  const video = document.createElement('video');

  const sentryDialog = document.querySelector<HTMLElement>('.sentry-error-embed-wrapper');
  if (sentryDialog) sentryDialog.style.display = 'none';

  try {
    const captureStream = await navigator.mediaDevices.getDisplayMedia({
      video: {
        displaySurface: 'browser',
      },
      preferCurrentTab: true,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any);

    video.srcObject = captureStream;
    await video.play();

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    const frame = canvas.toDataURL('image/png');

    video.pause();
    captureStream.getTracks().forEach((track) => track.stop());

    return frame;
  } finally {
    if (sentryDialog) sentryDialog.style.display = '';
  }
}
