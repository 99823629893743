import { BehaviorSubject, Observable, filter, map } from 'rxjs';

export interface KioskApi {
  get kioskId(): string | null;
  kioskData$: Observable<{ user_id: number; username: string; password: string; hide_toolbar: boolean } | null>;
}

const _kioskApi$ = new BehaviorSubject<KioskApi | null>(null);

export const kioskApi$ = _kioskApi$.asObservable().pipe(
  filter((kioskApi) => !!kioskApi),
  map((kioskApi) => kioskApi!),
);

export const setKioskApi = (kioskApi: KioskApi) => {
  if (_kioskApi$.value) throw new Error('Kiosk API already set!');

  return _kioskApi$.next(kioskApi);
};
