import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { filterQueryParams } from '../utils/filter-query-params';

export function redirectKeepQueryParams(commands: unknown[], keepParams: string[]): CanActivateFn {
  return (route) => {
    const router = inject(Router);

    const queryParams = filterQueryParams(route.queryParams, keepParams);
    return router.createUrlTree(commands, { queryParams: queryParams, queryParamsHandling: 'merge' });
  };
}
