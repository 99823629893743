import { DataConnectorConfig, DataQuery, GraphQLDataConnectorConfig } from "@npm-libs/ng-templater";
import { RcgSingleDataConnector } from "./single-data-connector";
import { Observable, of } from "rxjs";

export class RcgDataConnectors {
    private dataConnectorConfigs!: DataConnectorConfig[];
    private connectors: { [x: string]: RcgSingleDataConnector } = {};

    constructor(dataconnectorsConfig: DataConnectorConfig[], debugData?: boolean) {
        this.dataConnectorConfigs = dataconnectorsConfig;
        this.subscribeDataConnectors(debugData);
    }

    getDataSlice$(dataPath: string): Observable<unknown> {
        const connector = this.getConnectorByDataPath(dataPath);
        if (!connector) {
            console.error(`getDataSlice$ - DataConnector not found for dataPath: ${dataPath}`);
            return of(undefined);
        }
        return connector!.data$;
    }

    updateQuery(queryPath: string, dataQuery: DataQuery): void {
        const connector = this.getConnectorByQueryPath(queryPath);
        if (!connector) {
            console.error(`updateQuery - DataConnector not found for queryPath: ${queryPath}`);
            return;
        }
        connector!.updateQuery(queryPath, dataQuery);
    }

    private subscribeDataConnectors(debugData?: boolean) {
        if (!this.dataConnectorConfigs || !this.dataConnectorConfigs.length) return;

        for (const dc of this.dataConnectorConfigs as GraphQLDataConnectorConfig[]) {
            if(!dc.graphql?.dataPath) {
                console.error(`subscribeDataConnectors - no data path for connector`);
                continue;
            }
            if(!dc.graphql?.queryRuntimeDataPath) {
                console.error(`subscribeDataConnectors - no queryRuntimeDataPath path for connector`);
                continue;
            }

            const connectorKey = `${dc.graphql.dataPath}#${dc.graphql.queryRuntimeDataPath}`;
            this.connectors[connectorKey] = new RcgSingleDataConnector(dc, connectorKey, debugData === true ? true : false);
        }
    }

    private getConnectorByQueryPath(queryPath: string): RcgSingleDataConnector | undefined {
        for (const [key, value] of Object.entries(this.connectors)) {
            const queryKey = key.split('#')[1];
            if (queryKey === queryPath) return value;
        }
        return undefined;
    }

    private getConnectorByDataPath(dataPath: string): RcgSingleDataConnector | undefined {
        for (const [key, value] of Object.entries(this.connectors)) {
            const dataKey = key.split('#')[0];
            if (dataKey === dataPath) return value;
        }
        return undefined;
    }

    destroy(): void {
        for (const c of Object.values(this.connectors)) {
            c.destroy();
        }
    }
}
