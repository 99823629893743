export function deepStringReplace(data: unknown, replacements: [string | RegExp, string][]) {
  const drill = (data: unknown): unknown => {
    if (!data) return data;

    if (typeof data === 'object') {
      for (const [k, v] of Object.entries(data)) {
        (data as Record<string, unknown>)[k] = drill(v);
      }

      return data;
    }

    if (Array.isArray(data)) {
      return data.map((d) => drill(d));
    }

    if (typeof data === 'string') {
      let d = data;

      for (const [searchValue, replaceValue] of replacements) {
        d = d.replaceAll(searchValue, replaceValue);
      }

      return d;
    }

    return data;
  };

  return drill(data);
}
