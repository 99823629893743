import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService, RcgTenant } from '@rcg/auth';
import { filter } from 'rxjs/operators';
import { RouterStateService } from './router-state.service';

@Injectable({
  providedIn: 'root',
})
export class InitalRouteTrackerService {
  private readonly router = inject(Router);
  private readonly authService = inject(AuthService);
  private readonly routerState = inject(RouterStateService);
  private readonly isInitialRoute: WritableSignal<boolean> = signal(false);

  private lastTenant: RcgTenant | null = null;
  private url = toSignal(this.routerState.url$.pipe(filter((u) => !!u)));
  private tenantChange$ = toObservable(this.authService.tenant).pipe(filter((t) => !!t?.id));

  constructor() {
    this.trackInitialRoute();
    this.redirectOnTenantChange();
  }

  private trackInitialRoute() {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      if (!this.isInitialRoute()) {
        this.isInitialRoute.set(true);
      } else {
        this.isInitialRoute.set(false);
      }
    });
  }

  private redirectOnTenantChange() {
    this.tenantChange$
      .pipe(
        filter((t) => !!t?.id),
        takeUntilDestroyed(),
      )
      .subscribe((tenant) => {
        if (!tenant?.id) return;

        const lastTenant = this.lastTenant;
        try {
          const url = this.url() ?? '';
          if (!this.isInitialRoute() && !!url && !!lastTenant?.id && tenant.id !== lastTenant?.id) {
            const urlPath = (url.startsWith('/') ? url.slice(1) : url).split('/').slice(0, 1).join('/');

            if (urlPath) {
              this.router.navigate([urlPath]);
            }
          }
        } catch (error) {
          console.error('Error on tenant change', error instanceof Error ? error?.message : `${error}`);
        } finally {
          this.lastTenant = tenant;
        }
      });
  }
}
