import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as Get from '@npm-libs/ng-getx';
import { AuthService } from '@rcg/auth';
import { tr } from '@rcg/intl';
import { deepEqual } from '@rcg/standalone';
import { BehaviorSubject, combineLatest, combineLatestWith, debounceTime, filter, map, of, switchMap } from 'rxjs';
import { SavedShortcuts, Shortcut, ShortcutTrigger, ShortcutWithTrigger } from '../models';
import { UserSettingsService } from './user-settings.service';

@Get.NgAutoDispose
@Injectable({
  providedIn: 'root',
})
export class ShortcutService {
  private readonly shortcutsR = new Get.Rx<ShortcutWithTrigger[]>([]);

  private readonly categorizedShortcutsR = this.shortcutsR.pipe(
    map((sc) =>
      sc.map((s) => {
        const category$ = s.categoryTr ? tr(s.categoryTr) : of(s.category ?? '???');
        const name$ = s.nameTr ? tr(s.nameTr) : of(s.name ?? '???');
        const description$ = s.descriptionTr ? tr(s.descriptionTr) : of(s.description ?? '???');

        return combineLatest([category$, name$, description$]).pipe(
          map(([category, name, description]) => ({
            ...s,
            category,
            name,
            description,
          })),
        );
      }),
    ),
    switchMap((sc$) => combineLatest(sc$)),
    map((sc) => sc.sort((a, b) => a.name.localeCompare(b.name))),
    map((s) =>
      s.reduce<{ [category: string]: ShortcutWithTrigger[] }>(
        (a, b) => ({
          ...a,
          [b.category]: [...(a[b.category] ?? []), b],
        }),
        {},
      ),
    ),
    map((c) => Object.entries(c)),
    map((c) => c.map(([category, shortcuts]) => ({ category, shortcuts }))),
  );

  public readonly shortcuts$ = this.shortcutsR.value$;
  public readonly categorizedShortcuts$ = this.categorizedShortcutsR.value$;

  private readonly savedShortcutsR = new Get.Rx<SavedShortcuts>({});

  private readonly shortcuts: Shortcut[] = [
    {
      categoryTr: 'shortcut_category_navigation_name',
      id: 'contactAdministration',
      icon: 'contacts',
      nameTr: 'shortcut_contact_administration_name',
      descriptionTr: 'shortcut_contact_administration_description',
      preventDefault: true,
      onTrigger: async () => this.router.navigate(['/administration', 'contacts']),
    },
    {
      categoryTr: 'shortcut_category_navigation_name',
      id: 'settings',
      icon: 'settings',
      nameTr: 'settings',
      descriptionTr: 'shortcut_settings_description',
      preventDefault: true,
      onTrigger: async () => this.router.navigate(['/settings']),
    },
  ];

  constructor(private authService: AuthService, private router: Router, private userSettingsService: UserSettingsService) {
    this.savedShortcutsR.subscribeTo(
      this.userSettingsService.shortcuts$.pipe(
        combineLatestWith(this.authService.authInfo$),
        filter(([, authState]) => !authState?.impersonator),
        map(([shortcuts]) => shortcuts),
      ),
    );

    document.documentElement.addEventListener('keydown', (e) => {
      const shortcut = this.shortcutsR.value.find((s) => {
        const t = s.trigger.value;
        return t && e.ctrlKey === t.ctrl && e.shiftKey === t.shift && e.altKey === t.alt && e.metaKey === t.meta && e.key === t.key;
      });

      if (!shortcut) return;
      if (shortcut.preventDefault) e.preventDefault();

      if (!shortcut.trigger.value!.init) {
        shortcut.trigger.next({
          ...shortcut.trigger.value!,
          init: true,
        });

        return;
      }

      shortcut.onTrigger(e);
    });

    this.shortcuts.forEach((s) => this.registerShortcut(s));

    this.shortcuts$
      .pipe(
        filter((s) => !!s.length),
        switchMap((s) =>
          combineLatest(
            s.map(({ category, categoryTr, id, trigger }) =>
              trigger.pipe(
                map((trigger) => ({
                  category: categoryTr ?? category,
                  id,
                  trigger,
                })),
              ),
            ),
          ),
        ),
        map((s) =>
          s
            .filter((s) => s.trigger?.init)
            .map((s) => ({ ...s, trigger: s.trigger! }))
            .reduce<SavedShortcuts>((a, { category, id, ...b }) => ({ ...a, [`${category}/${id}`]: b }), {}),
        ),
        debounceTime(1000),
        combineLatestWith(this.authService.authInfo$),
      )
      .subscribe(([s, authState]) => {
        if (!authState.user) return;
        if (authState.impersonator) return;
        if (deepEqual(this.savedShortcutsR.value, s)) return;

        this.userSettingsService.set('shortcuts', s);
      });
  }

  public async registerShortcut(shortcut: Shortcut) {
    const trigger = new BehaviorSubject<ShortcutTrigger | undefined>(undefined);

    this.savedShortcutsR.value$
      .pipe(map((savedShortcuts) => savedShortcuts[`${shortcut.categoryTr ?? shortcut.category}/${shortcut.id}`]?.trigger))
      .subscribe((t) => {
        trigger.next({
          ...trigger.value,
          ...t,
        });
      });

    this.shortcutsR.data = [
      ...this.shortcutsR.value,
      {
        ...shortcut,
        trigger,
      } as ShortcutWithTrigger,
    ];
  }
}
