import { Injectable } from '@angular/core';
import { ApolloError } from '@apollo/client/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { AuthService } from '@rcg/auth';
import { GraphqlClientService } from '@rcg/graphql';
import { Observable, catchError, filter, first, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { userAppModules } from '../gql/modules.gql';
import { IHasuraModule, IRoutableModule } from '../models/hasura-modul.model';

interface IModuleState {
  modules: IHasuraModule[];
  loading: boolean;
  error: unknown;
  initialized: boolean;
}

const initialState: IModuleState = {
  modules: [],
  loading: false,
  error: null,
  initialized: false,
};

type ModulePath = Record<number, { path: string[]; url: string; visibleInSelector?: boolean }>;

@Injectable({
  providedIn: 'root',
})
export class ModulesService extends ComponentStore<IModuleState> {
  constructor(private graphQlClient: GraphqlClientService, private authService: AuthService) {
    super(initialState);
  }

  readonly modulesState$ = this.select((state) => state);

  readonly allModules$ = this.select((state) => state.modules);

  private readonly allRoutableModules$ = this.select((state) => this.toRoutableModules(state.modules));

  readonly routableModules$ = this.select(this.allRoutableModules$, (modules) => modules.filter((m) => m.visibleInSelector));

  readonly isModuleAllowed$ = (routePath: string) =>
    this.select(
      this.authService.user$,
      this.modulesState$.pipe(filter((p) => p.initialized === true)),

      (user, modulesState) => {
        if (!user) return null;
        if (!modulesState || modulesState.error || modulesState.modules.length === 0) return null;

        return (
          this.toRoutableModules(modulesState.modules).findIndex((m) => {
            return m.url === routePath;
          }) !== -1
        );
      },
    ).pipe(first((v) => v !== null));

  readonly getUserModules = this.effect((_) =>
    _.pipe(
      tap(() => this.patchState({ loading: true, initialized: false, error: null })),
      withLatestFrom(
        this.authService.authInfo$.pipe(
          filter(({ tenant }) => !!tenant?.id),
          switchMap(({ user, tenant }) => {
            return (user ? this._getUserModules(user.id, tenant!.id) : of([])).pipe(
              tapResponse(
                (data) =>
                  this.patchState({
                    modules: data,
                    error: null,
                    loading: false,
                    initialized: true,
                  }),
                (error) =>
                  this.patchState({
                    modules: [],
                    error: error,
                    loading: false,
                    initialized: true,
                  }),
              ),
            );
          }),
        ),
      ),
    ),
  );

  private toRoutableModules(modules: IHasuraModule[]) {
    return (
      modules
        // eslint-disable-next-line no-prototype-builtins
        .filter((m) => this.implementedModules.hasOwnProperty(m.id))
        .map((module) => {
          const route = this.implementedModules[module.id] ?? this.implementedModules[0];
          return {
            id: module.id,
            icon: module.material_icon_name ?? '',
            name: module.name,
            description: module.comment ?? '',
            nameTranslationName: module.name_translation_name,
            descriptionTranslationName: module.comment_translation_name,
            url: route.url,
            params: null,
            path: ['/'].concat(route.path),
            sortingPosition: 1,
            activated: module.web_active,
            visibleInSelector: route.visibleInSelector ?? true,
          } as IRoutableModule;
        })
    );
  }

  private _getUserModules(userId: number, tenantId: number): Observable<IHasuraModule[]> {
    return this.graphQlClient
      .subscribe<{ data?: { module: IHasuraModule }[] }>({
        query: userAppModules,
        variables: { userId: userId, tenantId: tenantId },
      })
      .pipe(
        map((result) => result?.data?.map((m) => m.module) ?? []),
        catchError((e) => {
          if (e instanceof ApolloError && `${e}`.includes("'identity_get_user_modules' not found in type: 'subscription_root'")) {
            return of([]);
          }

          throw e;
        }),
      );
  }

  private implementedModules: ModulePath = {
    0: {
      path: [''],
      url: '',
    },
    38: {
      path: ['administration'],
      url: 'administration',
    },
    45: {
      path: ['administration', 'users'],
      url: 'administration/users',
      visibleInSelector: false,
    },
    282: {
      path: ['administration', 'contacts'],
      url: 'administration/contacts',
      visibleInSelector: false,
    },
    356: {
      path: ['administration', 'organizations'],
      url: 'administration/organizations',
      visibleInSelector: false,
    },
    357: {
      path: ['administration', 'modules'],
      url: 'administration/modules',
      visibleInSelector: false,
    },
    391: {
      path: ['administration', 'translations'],
      url: 'administration/translations',
      visibleInSelector: false,
    },
    393: {
      path: ['administration', 'tenants'],
      url: 'administration/tenants',
      visibleInSelector: false,
    },
    426: {
      path: ['administration', 'tags'],
      url: 'administration/tags',
      visibleInSelector: false,
    },
    466: {
      path: ['administration', 'groups'],
      url: 'administration/groups',
      visibleInSelector: false,
    },
    39: {
      path: ['absence'],
      url: 'absence',
    },
    41: {
      path: ['contact-center'],
      url: 'contact-center',
    },
    244: {
      path: ['screen-sharing'],
      url: 'screen-sharing',
    },
    247: {
      path: ['dashboard'],
      url: 'dashboard',
    },
    248: {
      path: ['calendar'],
      url: 'calendar',
    },
    281: {
      path: ['reports'],
      url: 'reports',
    },
    317: {
      path: ['servicedesk'],
      url: 'servicedesk',
    },
    320: {
      path: ['grid'],
      url: 'grid',
    },
    321: {
      path: ['dashboard-new'],
      url: 'dashboard-new',
    },
    322: {
      path: ['test-servicedesk'],
      url: 'test-servicedesk',
    },
    323: {
      path: ['projects'],
      url: 'projects',
    },
    390: {
      path: ['help-center'],
      url: 'help-center',
    },
    459: {
      path: ['lookup-tables'],
      url: 'lookup-tables',
    },
    460: {
      path: ['sales'],
      url: 'sales',
    },
    392: {
      path: ['presence'],
      url: 'presence',
    },
  };
}
