import { ComponentPortal, ComponentType, DomPortalOutlet } from '@angular/cdk/portal';
import { ApplicationRef, ComponentFactoryResolver, Injector } from '@angular/core';

export function createCDKPortal<C>(
  componentType: ComponentType<C>,
  componentFactoryResolver: ComponentFactoryResolver,
  applicationRef: ApplicationRef,
  injector: Injector,
  windowInstance: Window,
) {
  const outlet = new DomPortalOutlet(windowInstance.document.body, componentFactoryResolver, applicationRef, injector);

  document.querySelectorAll('style').forEach((htmlElement) => {
    windowInstance.document.head.appendChild(htmlElement.cloneNode(true));
  });

  windowInstance.document.title = 'Details';

  const containerPortal = new ComponentPortal(componentType);
  return outlet.attach(containerPortal);
}
