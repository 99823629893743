import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform, untracked } from '@angular/core';
import { DateUtils } from '@rcg/standalone';
import { Subscription, interval } from 'rxjs';

@Pipe({
  name: 'dateToNow',
  pure: false,
})
export class DateToNowPipe implements PipeTransform, OnDestroy {
  private _subscription: Subscription;

  private _value?: string;
  private _result?: string;

  constructor(changeRef: ChangeDetectorRef) {
    this._subscription = untracked(() =>
      interval(1000).subscribe(() => {
        if (this._value) this._setResult();
        changeRef.markForCheck();
      }),
    );
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  transform(value: string): string {
    if (value !== this._value) {
      this._value = value;
      this._setResult();
    }

    return this._result!;
  }

  private _setResult() {
    this._result = DateUtils.dateToNow(this._value!);
  }
}
