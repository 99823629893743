import { Injectable, inject } from '@angular/core';
import { AttachmentsService } from '../../../services';
import { tagBlobUrl } from '../fn/blob';
import { ResolverCheckFn, ResolverResolveFn } from '../models/resolver.model';
import { aidRegex, blobAidRegex, hexRegex } from '../regex';
import { HTMLAttachmentResolver } from './resolver';

@Injectable({
  providedIn: 'root',
})
export class AidHTMLAttachmentResolver extends HTMLAttachmentResolver<[]> {
  private readonly attachmentsService = inject(AttachmentsService);

  protected override check: ResolverCheckFn<[]> = () => [aidRegex] as const;

  protected override resolve: ResolverResolveFn<[]> = async (aids, updateProgress) => {
    let done = 0;

    const promises = aids.map(async (aid) => {
      const split = aid.split('/');
      if (split.length > 2 || !split.length) return Promise.resolve([aid, null] as const);

      let attachmentId = +split[0];

      if (isNaN(attachmentId) || !attachmentId) {
        const hash = split[0];
        if (!hexRegex.test(hash)) return Promise.resolve([aid, null] as const);

        attachmentId = await this.attachmentsService.getAttachmentIdFromHash(hash);
      }

      const thumb = split.length >= 2 && split[1] === 'thumb';

      return tagBlobUrl(this.attachmentsService.getAttachmentBlobUrlById(attachmentId, thumb), 'aid', aid).then((b) => {
        updateProgress(++done / aids.length, { [aid]: b });
        return [aid, b] as const;
      });
    });

    return Object.fromEntries(await Promise.all(promises));
  };

  public override sealBlobs = (html: string) => {
    return html.replace(blobAidRegex, (_, aid: string) => {
      return `"aid:${aid}"`;
    });
  };

  public override bakeBlobs = (html: string) => {
    return html.replace(blobAidRegex, (a, aid) => {
      return `"aid:${aid}"`;
    });
  };
}
